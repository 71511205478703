import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banquePro/banquePro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const go = '/go/?go=Manager.one&url=https://signup.manager.one/?offer=Bankbank'
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 4.4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 4.5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Manager.one : à lire absolument avant d'ouvrir un compte pro</title>
                <meta name="description" content="
              Manager.one est une banque pro avec cartes bancaires illimités avec un seul abonnement. Parfaite pour les grosses entreprises. Avis Manager.one de BankBank." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'black'}}>Banque Pro</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Manager.one</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Manager.one</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>À première vue, la banque pro Manager.one semble plus chère que ses concurrents directs. Mais à partir de 2 collaborateurs, ce compte pro serait-il le moins cher du marché ? Notre avis Manager.one :</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Offre Manager.one <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/manager-one-logo.png'
			width={250}
			alt='logo manager.one'
			placeholder='tracedSVG'
			/>
</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



		<h2>Compte Pro Manager.one</h2>
		<p>C’est un compte tout compris à 29.99€/mois sans engagement et avec cartes bancaires illimitées. Manager.one est une banque pro pour :</p>
		<ul>
			<li>indépendants (autoentrepreneur et freelance)</li>
			<li>sociétés déjà créées (EURL, SARL, SASU, SAS, SA)</li>
			<li>nouvelles entreprises (dépôt de capital)</li>
		</ul>

<iframe id='videoYT'src="https://www.youtube.com/embed/enbIJOkj3qo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>

<h2>Cartes bancaires illimitées, ça veut dire quoi ?</h2>
<p>Manager.one ne facture que la fabrication et l’envoi d’une carte bancaire pour 20€. Il n’y a aucun frais supplémentaire. Il sera donc très économique de remettre une carte bancaire à chacun de vos collaborateurs.</p>

<div style={{marginLeft: -5}}>
<DataTable row={[8]} id='postTable'/>
<DataTable row={[8]} id='mobileTable'/>

</div>

<h2>Des services qui rendent l’offre Manager.one différente de ses concurrents</h2>
<p>Les cartes bancaires Manager.one sont 100% paramétrables.</p>
<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/manager-one-site.png'
			width={800}
			alt='site manager.one'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>

			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Services Manager.one <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>



	
		<h3>	Les notes de frais, c’est enfin fini !</h3>
		<p>
Avec l’abolition complète des notes de frais, l’application de Manager.one offre un service unique. Cette fonction se met en place en 3 étapes :
</p>
<p>
Étape 1 – en cochant une simple case dans votre tableau de bord, vous aurez la possibilité de demander un justificatif obligatoire après chaque transaction.
</p><p>
Étape 2 – prenez en photos votre reçu après chaque transaction.
</p><p>
Étape 3 – utilisez l’option lecture intelligente des reçus pour renseigner les informations de votre transaction en 1 seconde. Dans le cas d’un repas d’affaire, le lecteur reconnaîtra par exemple le nombre de couverts ainsi que la TVA associée.
</p>

<h3>Catégorisation automatique de vos dépenses</h3>
<p>
Un temps monstrueux en administration et comptabilité est perdu à analyser les différentes transactions et indiquer à quelle catégorie elles correspondent. Manager.one propose une solution qui reconnait chaque dépense et la catégorise automatiquement.
</p>
<h3>La fonction blocage</h3>

<h4>Contrôle et sécurité</h4>
<p>
Par raison de sécurité ou de contrôle, vous allez parfois avoir besoin de limiter certaines fonctionnalités des cartes bancaires distribuées à votre équipe. Manager.one offre la possibilité de bloquer jusqu’à 10 types de paiements et retraits en 1 seul clic :
</p>
<ul>
	<li>Paiement sans contact</li>
	<li>Retrait d’espèces</li>
	<li>Paiement internet et vente à distance</li>
	<li>Paiement en devises étrangères</li>
	<li>Blocage du nombre de transactions par jour</li>
	<li>Blocage de la carte certains jours</li>
	<li>Bloquer des plages horaires sans transaction possible</li>
	<li>Bloquer des pays</li>
	<li>Limiter les paiements par mots clés : en ajoutant par exemple le mot clé “Uber”, votre carte bancaire sera déclinée lors de votre paiement chez ce prestataire</li>
	<li>À l’inverse du blocage de mots clés, vous pouvez autoriser les transactions seulement chez certains commerçants</li>
</ul>

<h3>Plafond de cartes paramétrables avec alertes</h3>
<p>
Chaque carte Visa peut être plafonnée par jour, semaine et par mois. Ces plafonds sont accompagnés d’un système d’alerte sur vos critères. Les notifications sont là aussi paramétrable de votre tableau de bord :
</p>
<ul>
	<li>Alerte plafond atteint</li>
	<li>Alerte seuil de dépense</li>
</ul>

<h3>Espace clients ultra complet</h3>
<ul>
<li>Naviguer facilement entre vos différentes sociétés</li>
<li>Envoi express de votre RIB / IBAN</li>
<li>Ajout de pièce justificative à chaque transaction</li>
<li>Export de données</li>
<li>Gestion des utilisateurs avec validation des droits d’accès</li>
</ul>



<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Avis Manager.one – Votre comptabilité sera simplifiée pour toujours</h2>

<h3>Donnez un accès direct à votre expert comptable</h3>
<p>
Vous n’aurez plus besoin de télécharger vos transactions et de les envoyer à votre comptable par email. Manager.one permet de donner un accès directement à votre partenaire expert comptable avec un export simplifié en format Excel, CSV, OFX ou encore QIF.
</p><p>
Comme tous les justificatifs peuvent être associés directement à chaque transaction, votre comptable pourra télécharger directement ces éléments de votre tableau de bord. Vous n’aurez plus besoin de faire des allers-retours par email qui paralyse le développement de votre entreprise le temps de quelques heures tous les mois.
</p><p>
Si votre comptable ne veut pas se connecter, vous pourrez paramétrer un envoi automatique de chacun de vos relevés bancaires.
</p>
<h3>
Connexion automatique à votre logiciel comptable</h3>
<p>
Manager.one s’est associé avec Budget Insight pour permettre d’autoriser la connexion de votre compte bancaire à votre logiciel comptable comme (liste non exhaustive) :
</p>
<ul>
<li>Cegid</li>
<li>Loop</li>
<li>QuickBooks</li>
<li>Sage</li>
<li>Ebp</li>
</ul>

<h3>Tous les salaires payés en 1 seul clic</h3>
<p>
Payez vos salaires en quelques secondes en uploadant vos différents bulletins de salaire.
</p><p>
Manager.one envoi aussi votre attestation d’augmentation de capital sous 48h.
</p>

<h3>
Notre avis Manager.one pour les chèques et l’espèce</h3>
<p>
Si la banque ne met pas en avant ces services, il est possible à titre exceptionnel de faire des dépôts de chèque et d’espèces. Manager.one recommande cependant fortement la passage du paiement à la carte bancaire et virement.
</p>

<Link to='/banque-pro/'> Comparez les offres de banque pros qui acceptent les dépôts de chèques</Link>

<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Aller sur le site <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Avis Manager.one</h2>
	<ul>
		<li>App store : 5 / 5 (1 seul avis)</li>
		<li>Google play : 4.4 / 5
</li>
	</ul>


<h3>Avantages</h3>
<ul>
<li>Tarif exceptionnel : 2 collaborateurs</li>
<li>Abolition des notes de frais</li>
<li>Envoi de notification à chaque transacti</li>
<li>Carte bancaire sans plafond</li>
<li>Un seul abonnement pour toute votre entreprise</li>
<li>Paiement des salaires en 2 minutes</li>
<li>Connexion de votre compte à votre logiciel comptable</li>
<li>Ouverture de sous-comptes avec IBAN dédié</li>
<li>Obtention immédiate d’une attestation de virement</li>
<li>Chaque collaborateur disposera d’un tableau de bord personnalisé avec ses propres transactions.</li>
</ul>


<h3>Inconvénients</h3>

<ul>
	<li>Notre avis Manager.one, si vous êtes dépendant de paiement par chèque ou en espèce, cette banque n’est probablement pas pour vous.</li>
	<li>Les paiements hors de la zone euro sont assez chers</li>
</ul>

<h2>Ouvrir un compte en ligne Manager.one</h2>
<p>
L’ouverture d’un compte Manager.one se fait en 5 minutes et est 100% en ligne.
</p>
<p>
Étape 1 – Choisissez si il s’agit d’une ouverture de compte ou un dépôt de capital
</p><p>
Étape 2 – Ajoutez vos justificatifs
</p>
<ul>
<li>Documents d’identités des dirigeants et associés</li>
<li>Justificatif de domicile du dirigeant</li>
<li>Statuts ou projet de statuts de votre entreprise</li>
<li>Justificatif du siège social</li>

</ul>

<p>
Étape 3 – KYC – quelques questions obligatoires pour mieux vous connaître
</p><p>
Étape 4 (pour les sociétés existantes) – Faites un virement de 50€ de votre banque actuelle
</p><p>
Votre ouverture de compte sera validée sous 48 heures.
</p><p>
Étape 4 bis (pour les créations d’entreprise) – vous recevrez le certificat de dépôt de capital sous 48 heures.
</p>
<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>

		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
